.mainSection {
    width: 100%;

    .heroSection {
        width: 100%;
        height: 30vh;
        background-image: url('../../images/ABSTRACT_BACKGROUND_01_50.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h5 {
            font-size: 40px;
            font-weight: 400;
            color: white;
        }

        h6 {
            font-size: 20px;
            color: #FF0000;
            font-weight: 400;
        }
    }
}

.breakText{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.productImage{
    max-width: 100%;
    height: auto;
}
.cardContent{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.textOnHover:hover{
    color: red;
}
@media screen and (max-width: 576px) {
    .myCol{
      width: 50% !important;
    }
    .myContainer{
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}
.myContainer{
    padding-right: 3rem;
    padding-left: 3rem;
    // .row{
    //     .col-lg-4{
    //             // hr {
    //             //     border: none;
    //             //     border-top: 1px solid grey;
    //             //     margin: 2rem auto 2.5rem !important;
    //             // }
    //     }
    // }
}

.paginationbutton{
    // margin-left:20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    height: 2rem;
    // font-weight: 400;
    font-size: 1rem;
    padding: 0 0.3rem;
    line-height: 1;
    color: #706f6c;
    border-radius: 0.3rem;
    background-color: transparent;
    border: 0.1rem solid transparent;
    background-color: rgb(226, 226, 226);
  }
  .paginationbutton:hover{
    border: 1px solid grey;
  }
  .downOp{
    opacity: 0.4;
    border: 0px !important;
  }

  .noProductSection{
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .headingOfTopBrand{
    font-size: 2.3rem;
    font-weight: 600;
  }
  .subHeadOfTop{
    font-size: 18px;
    color: red;

  }

  
  