.topHover,
.username {
  transition: all 0.2s ease-in-out;
  display: flex;
  color: white !important;
  padding: 0;
  &:hover {
    color: #FF0000 !important;
  }
}
.wish_cart{
  &:hover{
    color: #FF0000 !important;
  }
}
.username {
  font-weight: 300;
  font-size: 0.9rem;
  cursor: pointer;
}

.signSelect {
  color: #FF0000 !important;
}

.hr {
  border-color: #777;
  height: 1px;
  margin: 0;
}

.modal-signin-signup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  // background-color: #fff;
  box-shadow: 0px 11px 15px -7px RGB(0 0 0 / 20%),
    0px 24px 38px 3px RGB(0 0 0 / 14%), 0px 9px 46px 8px RGB(0 0 0 / 12%);
  padding: 2rem 4rem;
  overflow: auto;
  height: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: red;
      font-size: 0.7rem;
    }
  }

  label {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 11px;
  }

  input {
    border: 1px solid #ebebeb;
    outline: none;
    background-color: #fafafa;
    transition: all 0.3s ease-in-out;
    padding: 9px 20px 9px 20px;
    font-size: 14px;

    &:focus {
      background: #fff;
      border-color: #FF0000;
    }
  }
}

.login {
  display: flex;

  .none {
    &:hover {
      background: #fff !important;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    font-size: 14px;
    color: #FF0000;
    border: 1px solid #FF0000;
    min-width: 115px;
    padding: 8.5px 15px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #FF0000;
      color: #fff;
    }

    svg {
      font-size: 1.7rem;
    }
  }

  .checkBox {
    margin-left: 1rem;
    font-size: 14px;
    color: #666666;
  }
}

.bottom {
  p {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: #777777;
    margin-bottom: 1rem;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #f5f6f9;
    border: 1px solid #ebebeb;
    font-size: 1rem;
    padding: 8.6px 10px;
    cursor: pointer;

    svg {
      font-size: 1.4rem;
      margin-right: 0.7rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal-signin-signup {
    width: 80%;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.isActive{
  color: red !important;
  // border-bottom: 2px solid red;
}
//Logout
.myHeaderDropDown{
  position: relative;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.myLinkDropDown{
  position: relative;
    display: inline-flex;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    // padding-right: 2.2rem;
    align-items: center;
    text-transform: uppercase;
}
.myHeaderMenu{
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  min-width: 100%;
  width: 200px;
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  box-shadow: 2px 5px 8px rgba(51,51,51,.05), -2px 5px 8px rgba(51,51,51,.05);
  transition: all .25s;
  margin-top: 1px;
}
.myHeaderMenu ul{
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}
.myHeaderMenu ul li{
  list-style: none;
  padding-bottom: 15px;
  padding-top: 10px;
  padding-left: 10px;
  margin: 0;
  color: #777;
  border-bottom: 1px solid gainsboro;
}
.myHeaderMenu ul li:hover{
  color: red;
  transition: 0.8s ease;
}
.myHeaderDropDown:hover>.myHeaderMenu {
  visibility: visible;
  opacity: 1;
}