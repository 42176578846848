* {
    font-family: 'Poppins' !important;
}

.mainSectionTeam {
    background-color: #eef4f7;
}

.container_about {
    max-width: 1545px !important;
}

.about_us {
    width: 100%;
    height: 30vh;
    // max-height: 400px;
    display: flex;
    background: url('../../images/ABSTRACT_BACKGROUND_01_50.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.vision_mission_h2 {
    font-size: 2.25rem !important;
    font-weight: 400 !important;
}

.para_mission_vission {
    letter-spacing: 0px !important;
    word-spacing: 5px;
    margin-top: 15px !important;
}

.sec_who_we_are {
    background-color: #f9f9f9 !important;
}

.meet_our_team_heading {
    width: 100%;
    text-align: center;
    color: black !important;
}

.card_card_card {
    // width: 30%;
    // max-width: 30% !important;
    position: relative;
    box-shadow: 5px 5px 20px grey;

    &:hover {
        cursor: pointer;

        .content_card {
            opacity: 1;
            transition: opacity 1.2s ease;
        }
    }


}


.image_card {
    width: 100%;
    max-width: 100%;

}

.content_card {
    background-color: #00000082;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 25%;
    opacity: 0;
}

.boxCard {
    background-color: white;
    text-align: center;
    padding: 30px 30px;
    border-radius: 20px;

    .title {
        text-transform: uppercase;
        font-weight: bold;
        color: #d0d0d0;
        letter-spacing: 2px;
        font-size: 13px;
    }

    .image {
        max-width: 200px;
        height: auto;
    }
    .name {
        font-weight: 500;
        margin-top:28px;
        margin-bottom:8px;
        color:inherit;
      }
      .description{
        font-size:15px;
        margin-top:15px;
        margin-bottom:20px;
      }
}