span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock{
  font-family: 'Poppins';
}
.btn__ctg{
  height: 55px;
}
.nav_bar{
    background-color: transparent;
    color:  black  !important;
    display: flex;
    padding: 0 !important;
    height: 100%;
    align-items: center;
    width: fit-content !important;
    /* margin: 0 I !important; */
}
.category_drop{
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
}
.category_drop:hover{
    background-color: #FF0000;
    width: 100% !important;
}
.category_drop:hover #dropdownMenuButton1{
    color: white  !important;
}
button#dropdownMenuButton1 {
    background: none !important;
    border: none !important;
    color: black  !important;
}
button#dropdownMenuButton1:focus{
    border: none !important;
    box-shadow: none !important;
}
button#dropdownMenuButton1::before {
    content: "\002631";
    margin-right: 10px;
}
.category_drop ul li a{
    text-decoration: none;
    color: black;
    text-transform: capitalize;
}
.category_drop ul li{
    padding: 5px 10px ;
}
.inner_drop{
    border-bottom: 1px solid rgba(0,0,0,.15);

}
/* .category_drop ul li:first-child{
    padding-top: 5px;
} */
.category_drop ul li:last-child{
    padding-bottom: 5px !important;
    border-bottom: none !important;
}
.dropdown-menu {
    background-color: white;
    width: 100%  !important; 
    border-radius: 0px !important;
    border: 1px solid rgba(0,0,0,.15) !important;
    border-top: none !important;
    left: 0;
    right: 0;
    top: 100%;
}
.dropdown:hover>.dropdown-menu {
    display: block;
  }
  
  .dropdown>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
      pointer-events: none;
  }

  .inner_drop:hover{
    cursor: pointer;
  }
  .inner_drop:hover .inner_anchor{
    color: #FF0000 !important;
  }
  .inner_drop_menu{
    display: none;
  }
  .inner_drop:hover .inner_drop_menu{
    display: block;
  }
  li.mega_mnu_list:hover .mega_menu_links {
    color: #FF0000;
    padding: 3px 10px;
    border-radius: 5px;
    border: 1px solid;
    margin-left: 10px;
    transition: .3s margin;
}
.abc ul li {
  padding: 5px 10px;
}
.abc ul li a{
  font-family: 'Poppins';
}
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .inner_drop:hover .inner_drop_menu {
    display: flex  !important;
    position: absolute;
    width: 100%;
    left: 244px;
    background-color: white;
    border-top: none !important;
    border: 0.3px solid darkgray;
    /* height: 100%; */
    overflow-y: auto;
    top: 0;
    padding: 15px 0px;
    animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}
ul li{
    list-style: none;
}
@media only screen and (max-width: 1080px) {
  .inner_drop:hover .inner_drop_menu{
    width: 70vw;
  }
}
.MuiButtonBase-root{
  /* width: 100% !important; */
  padding: 0px 16px !important;
}