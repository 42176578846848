.mainSecionOrder {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .orderBoxSection {
        max-width: 90%;
        padding: 30px;
        text-align: center;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        h3 {
            color: red;
            font-weight: 500;
            margin-top: 20px;
            font-size: 25px;
        }
    }

    .orderButton {
        background: none;
        color: red;
        border: 1px solid red;
        padding: 12px 15px;
        margin-top: 15px;
    }

}

@media screen and (max-width: 449px) {
    .mainSecionOrder {
        .orderBoxSection {
            padding: 15px;
            h3{
                font-size: 20px;
            }
        }
    }
}