.mainSection{
  width: 100%;
  background-color: #1A1A1A;
  .contain{
    max-width: 1400px !important;
  }
}
.link{
  padding-bottom: 15px;
}
.headingNo{
  font-size: 1rem;
    font-weight: 600;
    margin-bottom: 19px;
    color: red !important;
}
.main {
  padding: 60px 0px 60px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .grid1 {
    img {
      margin-bottom: 12px;
    }

    p {
      font-size: 14px;
      line-height: 26px;
      color: white;
      font-weight: 300;
      margin-bottom: 12px;
    }

    .call {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 1px solid #ebebeb;
      padding: 16px 20px 16px 60px;

      h5 {
        font-size: 13px;
        font-weight: 300;
        color: white;

        span {
          color: white;
          font-weight: 400;
          font-size: 1.25rem;
          transition: 0.3s color ease-in-out;
          &:hover {
            color: #FF0000;
          }
        }
      }

      svg {
        display: inline-block;
        color: #FF0000;
        font-size: 30px;
        line-height: 1;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        position: absolute;
      }
    }
  }
}

.list {
  margin-bottom: 40px;
  h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 19px;
    color: red !important;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 9px;
      font-size: 14px;
      font-weight: 300;
      color: white;
    }
  }
}

.bottom {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  background-color: #20242E;
  padding: 1.1rem 0;

  p {
    color: white;
    font-size: 14px;
    font-weight: 300;
    a{
      color: red !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .bottom {
    flex-direction: column;
  }
  .main{
    padding: 30px 0px 30px 0xp;
  }
}
