.bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../../images/page-header-bg.jpg);
  background-color: #ebebeb;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 45px 0;

  h5 {
    font-size: 40px;
    font-weight: 400;
    color: #333;
  }

  h6 {
    font-size: 20px;
    color: #FF0000;
    font-weight: 400;
  }
}

.brdHov {
  transition: color ease-in-out 0.2s;
  &:hover {
    color: #FF0000;
  }
}

.table {
  width: 100%;
  color: #212529;
  border-collapse: collapse;

  thead {
    border-bottom: 0.1rem solid #ebebeb;
  }

  th {
    font-size: 14px;
    color: #999;
    font-weight: 400;
    padding: 14px 0;
    text-align: inherit;
  }

  tbody tr {
    padding: 30px 0;
    border-bottom: 0.1rem solid #ebebeb;
    align-items: center;

    .product {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: #333;
        display: inline-block;
        font-size: 1rem;
        width: 300px;
        white-space: nowrap;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .imgDiv {
      width: 80px;
      height: 80px;
      display: block;
      margin-right: 28px;
    }

    .imgCard {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .price {
      width: 180px;
      font-size: 1rem;
      font-weight: 400;
    }

    .deleteBtn {
      width: 38px;
    }
  }

  .addToCardBtn {
    width: 202px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 0.813rem;
      padding: 6px 15px;
      background: transparent;
      border: 1px solid #FF0000;
      color: #FF0000;
      height: 40px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #fff;
        background: #FF0000;
      }

      svg {
        font-size: 16px;
        margin-right: 1.125rem;
      }
    }
  }

  .deleteBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #cccccc;

    svg {
      transition: color ease-in-out 0.2s;
      font-size: 1.2rem;
      cursor: pointer;

      &:hover {
        color: #333;
      }
    }
  }
}

.socials {
  margin: 30px 0 20px;
  display: flex;
  align-items: center;

  p {
    color: #666;
    font-size: 14px;
    font-weight: 300;
  }

  .socialsIcon {
    display: flex;
    div {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e1e2e6;
      transition: all ease-in-out 0.3s;
      margin-left: 1rem;
      color: #777;

      &:hover {
        color: #FF0000;
        border-color: #FF0000;
      }
    }
  }
}

.emptyCart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0.4) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );

  p {
    font-size: 2rem;
    padding: 7rem 0;
  }
}

tr {
  position: relative;
}

@media screen and (max-width: 900px) {
  thead {
    display: none;
  }
  .table {
    tr {
      align-items: center;
      display: flex;
      flex-direction: column;
      border: 1px solid #e3e3e3;
      padding: 42px 0 40px;
    }

    tbody {
      tr {
        .product {
          justify-content: center;
          a {
            width: 60%;
            white-space: break-spaces;
          }
        }
      }
    }
  }
  td {
    text-align: center;
  }
  tbody tr {
    .price {
      margin: 1px 0;
    }
  }
  .addToCardBtn {
    margin-top: 15px;
  }
  .deleteBtn {
    position: absolute;
    right: 1.5rem;
    top: 0.5rem;
    padding: 0.5rem !important;
    width: auto !important;
  }
}
